footer#footer {
  color: #3b2002;
  display: flex;
  justify-content: center;
  background: whitesmoke;
  flex-shrink: 0;
  margin-top: auto;
}

.footer-container {
  height: 200px;
  text-shadow: rgb(194, 194, 194) 0 0 15px;
  text-align: center;
  width: 100%;
}
@media (min-width: 1440px) {
  .footer-container {
    width: 50%;
  }
}
@media (min-width: 1366px) {
  .footer-container {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .footer-container {
    width: 80%;
  }
}
.footer-container .footer-content .sitemap {
  display: flex;
  justify-content: center;
  padding: 0.2em 0.2em;
  flex-wrap: wrap;
  background: #d2b79d;
}
@media (min-width: 768px) {
  .footer-container .footer-content .sitemap {
    flex-wrap: wrap;
    padding: 1em 1em;
  }
}
.footer-container .footer-content .sitemap .sitemap-block {
  display: block;
  margin: 0 3em;
  width: 40%;
}
@media (min-width: 768px) {
  .footer-container .footer-content .sitemap .sitemap-block {
    width: 20%;
  }
}
.footer-container .footer-content .sitemap .sitemap-block .block-header {
  font-variant-caps: small-caps;
}
.footer-container .footer-content .sitemap .sitemap-block ul.block-links {
  list-style-type: none;
  padding-left: 0;
}
.footer-container .footer-content .sitemap .sitemap-block ul.block-links li > a {
  color: #311c01;
  text-decoration: underline;
  text-underline-style: dotted;
}
.footer-container .footer-content .sitemap .sitemap-block ul.block-links li > a:hover, .footer-container .footer-content .sitemap .sitemap-block ul.block-links li > a:active {
  background: #311c01;
  border-radius: 3px;
  color: white;
  text-decoration-style: dotted;
  text-decoration-skip-ink: auto;
}
.footer-container .footer-content .brands {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  justify-content: center;
  color: #3d2808;
  margin: 1em 0;
  letter-spacing: 0.5em;
  padding: 0.5em 0.5em;
}
@media (min-width: 768px) {
  .footer-container .footer-content .brands {
    font-size: 2.5rem;
    padding: 1em 1em;
    flex-wrap: nowrap;
    letter-spacing: 1.5em;
  }
}
.footer-container .copyright {
  margin: 1em 0;
  font-size: 0.6rem;
  color: black;
  text-align: center;
  text-transform: uppercase;
  padding: 1em;
}

body {
  min-height: 100vh;
}
body div#root {
  min-height: 100vh;
}
body div#under-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body div#under-root section#section {
  display: grid;
  justify-content: center;
  flex: 1 0 auto;
}
body div#under-root section#section > div {
  width: 100vw;
}
@media (min-width: 1440px) {
  body div#under-root section#section > div {
    width: 50vw;
  }
}
@media (min-width: 1366px) {
  body div#under-root section#section > div {
    width: 75vw;
  }
}
@media (min-width: 1024px) {
  body div#under-root section#section > div {
    width: 80vw;
  }
}

header#header {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
  background: -moz-linear-gradient(top, #502d0e 0%, #311c01 10%, #3b2002 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #502d0e 0%, #311c01 10%, #3b2002 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #502d0e 0%, #311c01 10%, #3b2002 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a90329", endColorstr="#6d0019", GradientType=0); /* IE6-9 */
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}
header#header #top-bar {
  display: flex;
  text-shadow: rgb(131, 131, 131) 0 0 5px;
  padding: 0.5em 0.5em;
  align-items: center;
  width: 100vw;
  height: 90px;
}
@media (min-width: 1440px) {
  header#header #top-bar {
    width: 50vw;
    height: auto;
  }
}
@media (min-width: 1366px) {
  header#header #top-bar {
    width: 75vw;
    height: auto;
  }
}
@media (min-width: 1024px) {
  header#header #top-bar {
    width: 80vw;
    height: auto;
  }
}
header#header #top-bar .overlaid {
  display: none;
}
header#header #top-bar #logo-div {
  width: auto;
  -moz-transition: width 0s ease 0.5s;
  transition: width 0s ease 0.5s;
}
header#header #top-bar #logo-div img {
  opacity: 1;
  -moz-transition: opacity 0s ease 0.5s;
  transition: opacity 0s ease 0.5s;
  color: white;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, rgba(0, 0, 0, 0.3333333333), #000 70%) right/300% 100%;
  mask: linear-gradient(-60deg, #000 30%, rgba(0, 0, 0, 0.3333333333), #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 3s infinite;
}
header#header #top-bar #logo-div.hidden {
  width: 0;
  -moz-transition: width 0s ease;
  transition: width 0s ease;
}
header#header #top-bar #logo-div.hidden img {
  opacity: 0;
  -moz-transition: opacity 0s ease 0s;
  transition: opacity 0s ease 0s;
}
@media (min-width: 1024px) {
  header#header #top-bar #logo-div {
    width: 25%;
  }
}
header#header #top-bar #search {
  width: 0;
  visibility: hidden;
  overflow-x: hidden;
  transition: visibility 0s ease;
  -moz-transition: visibility 0s ease;
  -webkit-transition: visibility 0s ease;
}
header#header #top-bar #search input {
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: width 0s;
  -moz-transition: width 0s;
  -webkit-transition: width 0s;
  margin-left: 2rem;
  left: 0;
  box-shadow: none;
  background-color: inherit;
  color: white;
  font-weight: 600;
  border: 0;
}
header#header #top-bar #search input:focus {
  outline: none;
}
header#header #top-bar #search.visible {
  visibility: visible;
  width: 100%;
}
header#header #top-bar #search.visible span {
  width: 80%;
  border-bottom: 1px dotted white;
  position: relative;
  display: inline-block;
}
header#header #top-bar #search.visible span:focus-within {
  border-bottom: 2px solid white;
  border-radius: 5px;
}
header#header #top-bar #search.visible span input {
  visibility: visible;
  opacity: 1;
  line-height: 2rem;
  left: auto;
  width: auto;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
header#header #top-bar #search.visible span #hide-search-icon {
  visibility: visible;
}
header#header #top-bar #search #hide-search-icon {
  cursor: pointer;
}
@media (min-width: 768px) {
  header#header #top-bar #search {
    display: inline-block;
    visibility: visible;
    width: 50%;
  }
  header#header #top-bar #search span {
    min-width: 100%;
    border-bottom: 1px dotted white;
    position: relative;
    display: inline-block;
  }
  header#header #top-bar #search span:focus-within {
    border-bottom: 2px solid white;
    border-radius: 5px;
  }
  header#header #top-bar #search span input {
    width: 90%;
    line-height: 2.5em;
    visibility: visible;
    opacity: 1;
  }
  header#header #top-bar #search #hide-search-icon {
    visibility: hidden;
  }
}
header#header #top-bar #search-icon {
  display: block;
  padding: 1em 1em;
  font-size: 1.5em;
  visibility: visible;
  -moz-transition: visibility 0s ease 0.5s;
  transition: visibility 0s ease 0.5s;
  cursor: pointer;
  margin: auto;
}
header#header #top-bar #search-icon.hidden {
  visibility: hidden;
  -moz-transition: visibility 0s;
  transition: visibility 0s;
}
@media (min-width: 768px) {
  header#header #top-bar #search-icon {
    display: none;
  }
}
header#header #top-bar div#menu-icon {
  right: 0;
  position: absolute;
  padding: 1em 1em;
  font-size: 1.5em;
  cursor: pointer;
}
@media (min-width: 768px) {
  header#header #top-bar div#menu-icon {
    display: none;
  }
}

#menu-overlay {
  visibility: hidden;
  height: 0;
  width: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 20px;
  padding: 0 10px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  z-index: 9999;
}
#menu-overlay #overlay-close-icon {
  display: none;
}
#menu-overlay.visible {
  background-color: rgba(34, 23, 14, 0.85);
  color: white;
  visibility: visible;
  height: 100vh;
  width: 100vw;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}
#menu-overlay.visible #overlay-close-icon {
  display: block;
  right: 0;
  position: absolute;
  padding: 1em 1em;
  font-size: 1.5em;
  cursor: pointer;
}
#menu-overlay.visible ul {
  width: auto;
}

#menu a {
  font-size: 1.25rem;
  color: black;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
    mask-position: left;
  }
}
nav {
  padding: 0.5em 0;
}
nav#top-nav {
  background-color: rgba(150, 112, 65, 0.35);
}
nav div#mobile-navbar {
  height: 100px;
  background: rgba(250, 250, 250, 0.34);
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  max-width: 100%;
}
nav div#mobile-navbar .mobile-rounded {
  border: 1px transparent;
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
}
nav div#mobile-navbar .mobile-rounded img {
  border-radius: 10%;
  max-height: 95%;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1024px) {
  nav div#mobile-navbar {
    display: none;
  }
}
@media (min-width: 1024px) {
  nav {
    padding: 0;
  }
}
nav ul#desktop-navbar {
  display: none;
  align-items: center;
}
nav ul#desktop-navbar > li.desktop-navbar-item {
  font-size: 1.25em;
  font-weight: 600;
  font-variant-caps: all-small-caps;
  text-align: center;
}
@media (min-width: 1024px) {
  nav ul#desktop-navbar {
    display: flex;
    min-height: 2rem;
    width: 100%;
    justify-content: center;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item {
    list-style-type: none;
    margin: 0 0.15em;
    transition-duration: 0.75s;
    display: block;
    height: 100%;
    padding: 0.1em 5rem;
    cursor: pointer;
    color: #573a0f;
    background-image: linear-gradient(270deg, #b99f7f 0%, #dcc3a4 20%, #dcc3a4 80%, #b99f7f 100%);
    border-radius: 2px;
    border: 1px solid #866e51;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list {
    opacity: 0;
    background: whitesmoke;
    position: absolute;
    align-self: center;
    left: 25rem;
    right: 25rem;
    text-align: left;
    transition: all 0.5s ease;
    visibility: hidden;
    cursor: auto;
    z-index: 9999;
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list li.overlay-text-li, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list a.overlay-text-link {
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list ul.overlay-img-list, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list a.overlay-img-link {
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list ul.overlay-img-list > div, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list a.overlay-img-link > div {
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list ul.overlay-img-list > div > img, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item ul.overlay-text-list a.overlay-img-link > div > img {
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover {
    background-image: linear-gradient(to left, #573a0f, #5d4a2f);
    color: white;
    border: 1px solid #fabc95;
    height: auto;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list {
    padding: 1rem 3rem;
    opacity: 1;
    height: 200px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 1rem;
    visibility: visible;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link {
    list-style-type: none;
    font-variant-caps: small-caps;
    width: 20rem;
    color: #424242;
    margin: 0.25em 0;
    padding: 0 0.25em;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 2rem;
    vertical-align: middle;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link > div {
    display: none;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within {
    background: #8a8484;
    color: white;
    border-radius: 4px;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within > div {
    display: block;
    float: right;
    align-items: center;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list {
    display: flex;
    height: 80%;
    width: 50%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 1.5rem 0;
    right: 25%;
    position: absolute;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list a.overlay-img-link, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list a.overlay-img-link, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list a.overlay-img-link, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list a.overlay-img-link {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 25%;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list li.overlay-img-li:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list a.overlay-img-link:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list li.overlay-img-li:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list a.overlay-img-link:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list li.overlay-img-li:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list a.overlay-img-link:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list li.overlay-img-li:hover, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list a.overlay-img-link:hover {
    border: 1px solid #573a0f;
    border-radius: 5px;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list a.overlay-img-link > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list a.overlay-img-link > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list a.overlay-img-link > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list a.overlay-img-link > div {
    display: flex;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:hover ul.overlay-img-list a.overlay-img-link > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li:focus-within ul.overlay-img-list a.overlay-img-link > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:hover ul.overlay-img-list a.overlay-img-link > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link:focus-within ul.overlay-img-list a.overlay-img-link > div > img {
    display: block;
    object-fit: contain;
    height: 70%;
    border-radius: 3px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list {
    display: none;
    top: 0;
    height: 0;
    width: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list a.overlay-img-link, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list li.overlay-img-li, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list a.overlay-img-link {
    text-align: center;
    display: none;
    flex-direction: column;
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list a.overlay-img-link > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list li.overlay-img-li > div, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list a.overlay-img-link > div {
    display: none;
    height: 0;
  }
  nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list li.overlay-text-li ul.overlay-img-list a.overlay-img-link > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list li.overlay-img-li > div > img, nav ul#desktop-navbar > li.desktop-navbar-item:hover ul.overlay-text-list a.overlay-text-link ul.overlay-img-list a.overlay-img-link > div > img {
    display: none;
    height: 0;
  }
}

.small-caps {
  font-variant-caps: small-caps;
}

.all-small-caps {
  font-variant-caps: all-small-caps;
}

.banner-div {
  width: 100%;
  background: white;
  text-align: center;
  display: flex;
  justify-content: center;
}
.banner-div div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-div img {
  object-fit: contain;
  width: auto;
  max-width: 95%;
}
.banner-div a {
  display: block;
}

div#banner-2 img {
  max-height: 30vh;
}
@media screen and (min-width: 992px) {
  div#banner-2 img {
    max-height: inherit;
  }
}

div#banner-1 {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  opacity: 0.8;
  height: 20vh;
  background-image: url("/public/legacy-images/temple-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
div#banner-1 h4 {
  height: 2rem;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  background: -webkit-linear-gradient(top, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(to top, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to top, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
  vertical-align: bottom;
  font-family: Algreya, serif;
  color: white;
}
@media screen and (min-width: 768px) {
  div#banner-1 {
    height: 20vh;
    flex-direction: row;
    justify-content: start;
  }
  div#banner-1 h4 {
    font-size: 3rem;
    flex-basis: 30%;
    height: 100%;
    align-self: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 5rem;
    background: -webkit-linear-gradient(left, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(to right, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
    -webkit-text-stroke: 0.4px black;
    -webkit-text-fill-color: white;
  }
}
@media screen and (min-width: 992px) {
  div#banner-1 {
    height: 30vh;
  }
}
@media screen and (min-width: 1200px) {
  div#banner-1 {
    height: 40vh;
  }
}

div#banner-2 {
  background: #c9cbd2;
  justify-content: space-evenly;
  border-radius: 10px;
}
div#banner-2 > div {
  border-radius: 5px;
  box-shadow: 0 -3em 3em rgba(241, 235, 235, 0.35), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(229, 228, 228, 0.55);
}

div#top-narrow-banner {
  height: 0;
  display: flex;
  width: 100%;
  text-align: center;
  visibility: hidden;
  background: #235d2f;
  color: white;
  border-radius: 2px;
  -moz-transition: visibility, height ease-in-out 0.75s;
}
div#top-narrow-banner.visible {
  visibility: visible;
  justify-content: center;
  height: 2rem;
  align-items: center;
}
div#top-narrow-banner.visible span {
  width: 85%;
}
div#top-narrow-banner.visible i {
  cursor: pointer;
  max-width: 2rem;
  margin: auto;
}

.product-cards-filter {
  width: 100%;
  padding: 1em 1em;
  font-variant-caps: all-petite-caps;
}
@media (min-width: 576px) {
  .product-cards-filter {
    width: 50%;
  }
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.small-caps {
  font-variant-caps: small-caps;
}

.all-small-caps {
  font-variant-caps: all-small-caps;
}

.product-card {
  margin: 1em 1em;
  min-width: 40vw;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgb(239, 236, 232) 5%, #ffffff 10%, #ffffff 90%, rgb(239, 236, 232) 100%);
  border-radius: 2px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.26);
}
@media (min-width: 576px) {
  .product-card {
    min-width: auto;
    width: 20%;
    margin: 1.5em 1.5em;
    min-height: 60px;
  }
}
.product-card div.header, .product-card div.footer {
  padding: 0.5em 0;
  text-align: center;
  font-weight: 500;
  color: #3b2002;
}
.product-card div.img-div {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.product-card div.img-div img {
  max-height: 500px;
  max-width: 100%;
  min-height: 100px;
  height: auto;
  color: dimgray;
}

h3#product-sku {
  font-variant-caps: small-caps;
  letter-spacing: 0.1em;
  margin: 0 0.1em;
}

#reach-us {
  font-size: 1.2em;
  color: dimgray;
  padding: 1em 0;
  border-top: 1px dotted dimgray;
  border-bottom: 1px dotted dimgray;
}
#reach-us #reach-us-text {
  font-size: 0.9em;
}
#reach-us a#product-whatsapp-link {
  color: #016751;
  border-bottom: 1px dotted #016751;
  padding: 0 0.5em;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 2em;
}
#reach-us a#product-whatsapp-link:hover {
  color: white;
  background: #016751;
}
#reach-us a#product-call-link {
  color: dimgray;
  border-bottom: 1px dotted dimgray;
  padding: 0 0.5em;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 2em;
}
#reach-us a#product-call-link:hover {
  color: white;
  background: dimgray;
}

#product-desc {
  max-height: 100px;
}

#btn-product-inquiry {
  width: 70%;
}
@media (min-width: 576px) {
  #btn-product-inquiry {
    width: 30%;
  }
}

#btn-product-heart {
  background: whitesmoke;
  border-color: #bb5757;
  color: #bb5757;
}
#btn-product-heart:hover {
  color: white;
  background: #bb5757;
  border-color: #bb5757;
}

#product-details-container {
  margin: 1em 0;
  padding: 2em;
  background: rgba(220, 220, 220, 0.8);
  border-radius: 5px;
  font-size: 0.85em;
}
#product-details-container > h3 {
  text-align: center;
}
#product-details-container .component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #a19d9d;
  padding: 3em 0;
}
#product-details-container .line-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c0bbbb;
  padding: 0.7em;
}
#product-details-container .line-item .label {
  color: dimgray;
}
#product-details-container .line-item .value {
  color: #262525;
}

#preview-img-container > img {
  width: 100%;
  height: auto;
}

.small-caps {
  font-variant-caps: small-caps;
}

.all-small-caps {
  font-variant-caps: all-small-caps;
}

.small-caps {
  font-variant-caps: small-caps;
}

.all-small-caps {
  font-variant-caps: all-small-caps;
}

h2#contact-us-header {
  max-height: 2rem;
}

div#contact-us-container {
  font-size: 1.2rem;
}
div#contact-us-container h2 {
  font-size: 2rem;
}
div#contact-us-container h4 {
  font-size: 1.5rem;
}
div#contact-us-container ul#stores-list {
  text-align: left;
}
div#contact-us-container ul#stores-list > li {
  margin: 2em 0;
  list-style-type: none;
}
div#contact-us-container ul#stores-list > li > div.store-name {
  font-variant-caps: small-caps;
  margin: 0.5em 0;
  font-weight: 500;
  font-size: 1.5rem;
}
div#contact-us-container ul#stores-list > li > div.store-address {
  max-width: fit-content;
}
div#contact-us-container ul#stores-list > li > div.store-address a {
  color: rgba(96, 68, 46, 0.76);
  border-bottom: 1px dotted rgba(96, 68, 46, 0.76);
  padding: 0 0.5em;
  border-radius: 2px;
  cursor: pointer;
}
div#contact-us-container ul#stores-list > li > div.store-address a:hover {
  color: white;
  background: rgba(96, 68, 46, 0.76);
}
div#contact-us-container ul#stores-list > li > div.store-address i {
  margin: 0.25em 0.25em !important;
}
div#contact-us-container ul#stores-list > li > ul li {
  margin: 0.25em 0;
  max-width: fit-content;
  list-style-type: none;
  text-decoration: none;
}
div#contact-us-container ul#stores-list > li > ul li a {
  color: rgba(96, 68, 46, 0.76);
  border-bottom: 1px dotted rgba(96, 68, 46, 0.76);
  padding: 0 0.5em;
  border-radius: 2px;
  cursor: pointer;
}
div#contact-us-container ul#stores-list > li > ul li a:hover {
  color: white;
  background: rgba(96, 68, 46, 0.76);
}
div#contact-us-container ul#stores-list > li > ul li a i {
  margin: 0 0.25em;
}
div#contact-us-container #contact-us-card {
  font-size: 1.25rem;
}
div#contact-us-container #contact-us-card small {
  font-size: 0.75em;
  color: rgba(131, 24, 24, 0.56);
}
div#contact-us-container #contact-us-card .p-card-subtitle {
  font-size: 1rem;
}
div#contact-us-container #contact-us-card button {
  font-size: 1.3rem;
  color: rgba(96, 68, 46, 0.76);
  border: 1px solid rgba(96, 68, 46, 0.76);
  background-color: whitesmoke;
  font-variant-caps: all-small-caps;
}
div#contact-us-container #contact-us-card button:hover {
  color: whitesmoke;
  background-color: rgba(96, 68, 46, 0.76);
  border: 1px solid rgba(96, 68, 46, 0.76);
}
div#contact-us-container #contact-us-card label {
  font-size: 1rem;
}
div#contact-us-container #contact-us-card .p-inputtext {
  font-size: 1.2rem;
  padding: 0.5rem 0.25rem;
}
div#contact-us-container #contact-us-card input {
  line-height: 1.4rem;
}

div#spinner {
  align-self: center;
  vertical-align: middle;
}
div#spinner circle.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, spinner-color 6s ease-in-out infinite;
  stroke: dimgray;
}

@keyframes spinner-color {
  100%, 0% {
    stroke: #361e02;
  }
  40% {
    stroke: #5e3708;
  }
  66% {
    stroke: #a67336;
  }
  80%, 90% {
    stroke: #daa96f;
  }
}
@-webkit-keyframes spinner-color {
  100%, 0% {
    stroke: #361e02;
  }
  40% {
    stroke: #5e3708;
  }
  66% {
    stroke: #a67336;
  }
  80%, 90% {
    stroke: #daa96f;
  }
}
@-moz-keyframes spinner-color {
  100%, 0% {
    stroke: #361e02;
  }
  40% {
    stroke: #5e3708;
  }
  66% {
    stroke: #a67336;
  }
  80%, 90% {
    stroke: #daa96f;
  }
}
.small-caps {
  font-variant-caps: small-caps;
}

.all-small-caps {
  font-variant-caps: all-small-caps;
}

.collection-page-container > h1 {
  font-weight: 800;
  font-family: Algreya, serif;
  color: rgb(58, 32, 11);
  font-size: 2rem;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .collection-page-container > h1 {
    display: none;
  }
}
.collection-page-container > div.content {
  margin: 2rem 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}
.collection-page-container > div.content h1 {
  font-weight: 800;
  font-family: Algreya, serif;
  color: rgb(58, 32, 11);
  font-size: 2rem;
  text-align: center;
  display: none;
}
@media screen and (min-width: 992px) {
  .collection-page-container > div.content h1 {
    display: block;
  }
}
.collection-page-container > div.content > div.description {
  padding: 3em 5em;
}
@media screen and (min-width: 992px) {
  .collection-page-container > div.content > div.description {
    width: 50vw;
  }
}
.collection-page-container > div.content > div.description p {
  font-size: 1.25rem;
}
@media screen and (min-width: 992px) {
  .collection-page-container > div.content > div.description p {
    margin: 5rem;
  }
}
.collection-page-container > div.content #collection-open-instagram {
  color: rgb(222, 28, 98);
  border: 1px solid rgb(222, 28, 98);
  background-color: whitesmoke;
  font-size: 1.1rem;
  padding: 1em 1rem;
  height: 3rem;
}
.collection-page-container > div.content #collection-open-instagram:hover {
  color: whitesmoke;
  background-color: rgb(222, 28, 98);
  border: 1px solid rgb(222, 28, 98);
}
@media screen and (min-width: 992px) {
  .collection-page-container > div.content {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }
}
.collection-page-container > div.content img {
  margin: 2em 0;
  max-height: 70vh;
  max-width: 90vw;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  object-fit: contain;
}
.collection-page-container div.other-images {
  margin-top: 4rem;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.collection-page-container div.other-images h3 {
  text-align: center;
  width: 100%;
  padding: 1em 0;
  border-bottom: 1px solid dimgray;
  border-top: 1px solid dimgray;
}
.collection-page-container div.other-images img {
  max-height: 60vh;
}
.collection-page-container div.other-images #other-images-carousel {
  max-width: 98vw;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .collection-page-container div.other-images #other-images-carousel {
    max-width: 50vw;
  }
}
@media screen and (min-width: 992px) {
  .collection-page-container div.other-images {
    flex-wrap: nowrap;
  }
}

.collections-container {
  justify-content: center;
}
.collections-container > a {
  display: block;
  text-align: center;
}
.collections-container > a img {
  max-width: 90%;
  max-height: 90vh;
  margin: 2em 0;
}

