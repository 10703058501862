/* Predefined Colors */
/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.card.card-w-title {
  padding-bottom: 16px;
}
.card.no-gutter {
  margin-bottom: 0;
}

.nopad {
  padding: 0;
}
.nopad .ui-panel-content {
  padding: 0;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.modal-in {
  -webkit-animation-name: modal-in;
  animation-name: modal-in;
}

.login-body {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  height: 100vh;
}
.login-body .login-image {
  height: calc(100% - 380px);
  background-size: cover;
  background-position: center;
}
.login-body .login-panel {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 0;
  border-radius: 0;
  bottom: 0;
  text-align: center;
}
.login-body .login-panel .login-panel-content {
  position: relative;
  margin: auto;
  top: 30px;
  color: #ffffff;
}
.login-body .login-panel .login-panel-content .login-label {
  color: #cccccc;
  margin-bottom: 5px;
}
.login-body .login-panel .login-panel-content .login-input {
  margin-top: 5px;
}
.login-body .login-panel .login-panel-content .guest-sign-in {
  color: #999999;
  font-size: 12px;
}
.login-body .login-panel .login-panel-content .welcome-text {
  margin-bottom: 0;
}
.login-body .login-panel .login-panel-content .link-pane {
  padding-top: 24px;
}
.login-body .login-panel .login-panel-content .link-pane button {
  color: #cccccc;
}
.login-body .login-panel .login-panel-content .button-pane {
  padding-top: 16px;
}

@media (max-width: 640px) {
  .login-body .login-panel .login-panel-content {
    padding: 0 20px;
    width: 100%;
  }
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: -apple-system, Algreya, Roboto, "Helvetica", Arial, sans-serif;
  font-size: 14px;
  color: #212529;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #eef2f6;
}
body a {
  text-decoration: none;
  color: #343a40;
}

.layout-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999997;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}

.layout-wrapper .layout-wrapper-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.layout-wrapper .topbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 60px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.layout-wrapper .topbar .topbar-left {
  padding: 15px;
  height: 60px;
  width: 235px;
  float: left;
  background-color: #343a40;
  background-image: -webkit-gradient(linear, left top, right top, from(#343a40), to(#424a51));
  background-image: -webkit-linear-gradient(to right, #343a40, #424a51);
  background-image: -moz-linear-gradient(to right, #343a40, #424a51);
  background-image: -ms-linear-gradient(to right, #343a40, #424a51);
  background-image: -o-linear-gradient(to right, #343a40, #424a51);
  background-image: linear-gradient(to right, #343a40, #424a51);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#343a40", endColorStr="#424a51", gradientType="1");
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.layout-wrapper .topbar .topbar-left .topbar-logo {
  height: 30px;
}
.layout-wrapper .topbar .topbar-right {
  padding: 15px 0;
  height: 60px;
  background-color: #343a40;
  background-image: -webkit-gradient(linear, left top, right top, from(#343a40), to(#424a51));
  background-image: -webkit-linear-gradient(to right, #343a40, #424a51);
  background-image: -moz-linear-gradient(to right, #343a40, #424a51);
  background-image: -ms-linear-gradient(to right, #343a40, #424a51);
  background-image: -o-linear-gradient(to right, #343a40, #424a51);
  background-image: linear-gradient(to right, #343a40, #424a51);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#343a40", endColorStr="#424a51", gradientType="1");
}
.layout-wrapper .topbar .topbar-right #menu-button {
  color: #343a40;
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 219px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
}
.layout-wrapper .topbar .topbar-right #menu-button:hover {
  background-color: #f4f4f4;
}
.layout-wrapper .topbar .topbar-right #menu-button i {
  transition: all 0.3s;
  font-size: 32px;
}
.layout-wrapper .topbar .topbar-right #menu-button.menu-button-rotate i {
  transform: rotate(180deg);
}
.layout-wrapper .topbar .topbar-right #topbar-menu-button {
  display: none;
  color: #ffffff;
  vertical-align: middle;
  height: 36px;
  position: absolute;
  top: 12px;
  right: 25px;
  cursor: pointer;
  transition: all 0.3s;
}
.layout-wrapper .topbar .topbar-right #topbar-menu-button i {
  transition: color 0.5s;
  font-size: 30px;
}
.layout-wrapper .topbar .topbar-right .topbar-items .search-item .topbar-search {
  position: relative;
  top: -2px;
}
.layout-wrapper .topbar .topbar-right .topbar-items .search-item .topbar-search input {
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
  padding: 5px 12px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  transition: box-shadow 0.3s, border-color 0.3s;
  width: 200px;
}
.layout-wrapper .topbar .topbar-right .topbar-items .search-item .topbar-search input:focus {
  outline: 0 none;
  border: 1px solid #6c757d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 3px 0 #6c757d;
}
.layout-wrapper .topbar .topbar-right .topbar-items .search-item .topbar-search i {
  position: absolute;
  color: #6c757d;
  top: 5px;
  right: 10px;
  font-size: 18px;
}
.layout-wrapper .topbar-items li > ul {
  display: none;
}
.layout-wrapper .topbar-items li.active-top-menu > ul {
  display: block;
}
.layout-wrapper .topbar-items li .topbar-message img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}
.layout-wrapper .layout-menu-container {
  overflow: auto;
  position: fixed;
  width: 235px;
  z-index: 99;
  top: 60px;
  height: 100%;
  background-color: #ffffff;
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#ffffff));
  background-image: -webkit-linear-gradient(to right, #ffffff, #ffffff);
  background-image: -moz-linear-gradient(to right, #ffffff, #ffffff);
  background-image: -ms-linear-gradient(to right, #ffffff, #ffffff);
  background-image: -o-linear-gradient(to right, #ffffff, #ffffff);
  background-image: linear-gradient(to right, #ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#ffffff", endColorStr="#ffffff", gradientType="1");
  border-right: 1px solid #cccccc;
  transition: margin-left 0.3s;
}
.layout-wrapper .layout-menu-container .profile {
  padding: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.layout-wrapper .layout-menu-container .profile:after {
  content: "";
  display: table;
  clear: both;
}
.layout-wrapper .layout-menu-container .profile > button {
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  font-family: -apple-system, Algreya, Roboto, "Helvetica", Arial, sans-serif;
}
.layout-wrapper .layout-menu-container .profile > button:focus {
  outline: 0;
}
.layout-wrapper .layout-menu-container .profile > button .profile-image {
  width: 45px;
  height: 45px;
  float: right;
  margin-left: 4px;
}
.layout-wrapper .layout-menu-container .profile > button .profile-name {
  display: block;
  color: #212529;
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  margin-top: 4px;
}
.layout-wrapper .layout-menu-container .profile > button .profile-role {
  display: block;
  min-width: 100px;
  text-align: right;
  font-size: 12px;
  float: right;
  color: #6c757d;
}
.layout-wrapper .layout-menu-container .profile > button i {
  float: right;
  color: #6c757d;
  vertical-align: middle;
  transition: transform 0.3s;
}
.layout-wrapper .layout-menu-container .profile.profile-expanded i {
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu-container .profile-menu {
  font-size: 14px;
  font-family: -apple-system, Algreya, Roboto, "Helvetica", Arial, sans-serif;
  border-bottom: 1px solid #d6d5d5;
}
.layout-wrapper .layout-menu-container .profile-menu.layout-profile-menu-enter {
  max-height: 0;
}
.layout-wrapper .layout-menu-container .profile-menu.layout-profile-menu-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.layout-wrapper .layout-menu-container .profile-menu.layout-profile-menu-enter-done {
  transform: none;
}
.layout-wrapper .layout-menu-container .profile-menu.layout-profile-menu-exit {
  max-height: 1000px;
}
.layout-wrapper .layout-menu-container .profile-menu.layout-profile-menu-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-wrapper .layout-menu-container .profile-menu li:first-child {
  margin-top: 16px;
}
.layout-wrapper .layout-menu-container .profile-menu li:last-child {
  margin-bottom: 16px;
}
.layout-wrapper .layout-menu-container .profile-menu button {
  font-size: 14px;
  font-family: -apple-system, Algreya, Roboto, "Helvetica", Arial, sans-serif;
  cursor: pointer;
}
.layout-wrapper .layout-menu-container.layout-menu-dark {
  background-color: #363a41;
  background-image: -webkit-gradient(linear, left top, right top, from(#363a41), to(#363a41));
  background-image: -webkit-linear-gradient(to right, #363a41, #363a41);
  background-image: -moz-linear-gradient(to right, #363a41, #363a41);
  background-image: -ms-linear-gradient(to right, #363a41, #363a41);
  background-image: -o-linear-gradient(to right, #363a41, #363a41);
  background-image: linear-gradient(to right, #363a41, #363a41);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#363a41", endColorStr="#363a41", gradientType="1");
  border-right-color: transparent;
}
.layout-wrapper .layout-menu-container.layout-menu-dark .profile .profile-name,
.layout-wrapper .layout-menu-container.layout-menu-dark .profile .profile-role {
  color: #ffffff;
}
.layout-wrapper .layout-menu-container.layout-menu-dark .profile i {
  color: #ffffff;
}
.layout-wrapper .layout-menu-container.layout-menu-dark .profile-menu {
  border-bottom: 1px solid #4a4d54;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li.layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.65);
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li a,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li button {
  color: #ffffff;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li a i,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li button i {
  color: #ffffff;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li a:hover,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li button:hover {
  background-color: #4a4d54;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li a.router-link-active,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li button.router-link-active {
  color: #9aa1a6;
  background-color: #282b30;
  border-left-color: #b5babe;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li a.router-link-active i:first-child,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li button.router-link-active i:first-child {
  color: #9aa1a6;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > a,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > button {
  border-left-color: #b5babe;
  color: #b5babe;
  background-color: #282b30;
}
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > a i,
.layout-wrapper .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > button i {
  color: #b5babe;
}
.layout-wrapper.menu-layout-overlay .layout-menu-container {
  width: 235px;
  transition: transform 0.3s;
}
.layout-wrapper.menu-layout-overlay .layout-menu-container.layout-menu-container-inactive {
  transform: translateX(-100%);
}
.layout-wrapper.menu-layout-overlay .layout-main,
.layout-wrapper.menu-layout-overlay .layout-footer {
  margin-left: 0;
}
.layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .layout-menu-container {
  z-index: 999999999;
}
.layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .layout-mask {
  display: block;
}
.layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right #menu-button i {
  transform: rotate(0deg);
}
.layout-wrapper.menu-layout-overlay .topbar {
  z-index: 999999998;
}
.layout-wrapper.menu-layout-overlay .topbar .topbar-right #menu-button i {
  transform: rotate(180deg);
}
.layout-wrapper .layout-main {
  padding: 80px 15px 15px 15px;
  transition: margin-left 0.3s;
  flex: 1 1 0;
}
.layout-wrapper .layout-mask {
  display: none;
}
.layout-wrapper .layout-footer {
  transition: margin-left 0.3s;
}
.layout-wrapper .layout-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.layout-wrapper .layout-menu.layout-main-menu {
  padding-bottom: 120px;
}
.layout-wrapper .layout-menu li.layout-root-menuitem .layout-menuitem-root-text {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.5rem 0 0.5rem 1.25rem;
  color: #6c757d;
}
.layout-wrapper .layout-menu li.layout-root-menuitem > button {
  display: none !important;
}
.layout-wrapper .layout-menu li a,
.layout-wrapper .layout-menu li button {
  font-family: -apple-system, Algreya, Roboto, "Helvetica", Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding: 10px 15px;
  color: #212529;
  width: 100%;
  transition: background-color 0.3s, border-left-color 0.3s;
  overflow: hidden;
  border-left: 4px solid transparent;
  border-radius: 0;
}
.layout-wrapper .layout-menu li a i,
.layout-wrapper .layout-menu li button i {
  color: #6c757d;
}
.layout-wrapper .layout-menu li a i:first-child,
.layout-wrapper .layout-menu li button i:first-child {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 16px;
}
.layout-wrapper .layout-menu li a i.menuitem-toggle-icon,
.layout-wrapper .layout-menu li button i.menuitem-toggle-icon {
  float: right;
  font-size: 16px;
  margin-top: 2px;
  margin-right: -3px;
  transition: transform 0.3s;
}
.layout-wrapper .layout-menu li a .submenu-arrow,
.layout-wrapper .layout-menu li button .submenu-arrow {
  display: none;
}
.layout-wrapper .layout-menu li a:hover,
.layout-wrapper .layout-menu li button:hover {
  background-color: #f4f4f4;
}
.layout-wrapper .layout-menu li a span,
.layout-wrapper .layout-menu li button span {
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .layout-menu li a .menuitem-badge,
.layout-wrapper .layout-menu li button .menuitem-badge {
  float: right;
  width: 16px;
  height: 16px;
  margin-right: 2px;
  text-align: center;
  background-color: #3eb839;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 50%;
  margin-top: 2px;
}
.layout-wrapper .layout-menu li a.router-link-active,
.layout-wrapper .layout-menu li button.router-link-active {
  border-left-color: #6c757d;
  color: #6c757d;
  background-color: #e8e8e8;
}
.layout-wrapper .layout-menu li a.router-link-active i:first-child,
.layout-wrapper .layout-menu li button.router-link-active i:first-child {
  color: #6c757d;
}
.layout-wrapper .layout-menu li.active-menuitem > a,
.layout-wrapper .layout-menu li.active-menuitem > button {
  color: #6c757d;
  background-color: #e8e8e8;
  border-left-color: #6c757d;
}
.layout-wrapper .layout-menu li.active-menuitem > a i:first-child,
.layout-wrapper .layout-menu li.active-menuitem > button i:first-child {
  color: #6c757d;
}
.layout-wrapper .layout-menu li.active-menuitem > a i.menuitem-toggle-icon,
.layout-wrapper .layout-menu li.active-menuitem > button i.menuitem-toggle-icon {
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu li.active-menuitem > ul {
  max-height: 1000px;
}
.layout-wrapper .layout-menu li > a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-wrapper .layout-menu li ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}
.layout-wrapper .layout-menu li ul.layout-submenu-container-enter {
  max-height: 0;
}
.layout-wrapper .layout-menu li ul.layout-submenu-container-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.layout-wrapper .layout-menu li ul.layout-submenu-container-enter-done {
  transform: none;
}
.layout-wrapper .layout-menu li ul.layout-submenu-container-exit {
  max-height: 1000px;
}
.layout-wrapper .layout-menu li ul.layout-submenu-container-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-wrapper .layout-menu li ul li a,
.layout-wrapper .layout-menu li ul li .p-link {
  padding: 10px 20px 10px 15px;
}
.layout-wrapper .layout-menu li ul li ul li a,
.layout-wrapper .layout-menu li ul li ul li .p-link {
  padding-left: 30px;
}
.layout-wrapper .layout-menu li ul li ul ul li a,
.layout-wrapper .layout-menu li ul li ul ul li .p-link {
  padding-left: 45px;
}
.layout-wrapper .layout-menu li ul li ul ul ul li a,
.layout-wrapper .layout-menu li ul li ul ul ul li .p-link {
  padding-left: 60px;
}
.layout-wrapper .layout-menu li ul li ul ul ul ul li a,
.layout-wrapper .layout-menu li ul li ul ul ul ul li .p-link {
  padding-left: 75px;
}
.layout-wrapper .layout-menu .layout-menu-tooltip {
  display: none;
  padding: 0 5px;
  position: absolute;
  left: 76px;
  top: 5px;
  z-index: 101;
  line-height: 1;
}
.layout-wrapper .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
  padding: 6px 8px;
  font-weight: 700;
  background-color: #4d535d;
  color: #ffffff;
  min-width: 75px;
  white-space: nowrap;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.layout-wrapper .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #4d535d;
}
.layout-wrapper .layout-footer {
  padding: 15px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.layout-wrapper .layout-footer i {
  font-size: 1em;
}
.layout-wrapper .layout-footer .footer-text-left img {
  height: 25px;
}
.layout-wrapper .layout-footer .footer-text-right {
  color: #6c757d;
  float: right;
}
.layout-wrapper .layout-footer .footer-text-right button {
  cursor: pointer;
  margin-left: 15px;
  color: #777777;
  font-size: 24px;
}

.ajax-loader {
  font-size: 32px;
  color: #6c757d;
}

@media (min-width: 1025px) {
  .layout-wrapper .topbar-items {
    -webkit-animation-name: none;
    animation-name: none;
    float: right;
    margin: 5px 0 0 0;
    padding: 0;
    list-style-type: none;
  }
  .layout-wrapper .topbar-items > li {
    float: right;
    position: relative;
    margin-left: 14px;
  }
  .layout-wrapper .topbar-items > li > a,
.layout-wrapper .topbar-items > li > button {
    cursor: pointer;
    position: relative;
    display: block;
  }
  .layout-wrapper .topbar-items > li > a:focus,
.layout-wrapper .topbar-items > li > button:focus {
    outline: 0;
  }
  .layout-wrapper .topbar-items > li > a .topbar-item-name,
.layout-wrapper .topbar-items > li > button .topbar-item-name {
    display: none;
  }
  .layout-wrapper .topbar-items > li > a .topbar-badge,
.layout-wrapper .topbar-items > li > button .topbar-badge {
    position: absolute;
    right: -6px;
    top: -6px;
    background-color: #3eb839;
    color: #ffffff;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-wrapper .topbar-items > li .topbar-icon {
    font-size: 24px;
    color: #ffffff;
    transition: color 0.3s;
  }
  .layout-wrapper .topbar-items > li .topbar-icon:hover {
    color: #cccccc;
  }
  .layout-wrapper .topbar-items > li.profile-item button {
    transition: color 0.3s;
    top: -3px;
  }
  .layout-wrapper .topbar-items > li.profile-item button:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-wrapper .topbar-items > li.profile-item button .profile-image {
    width: 36px;
    height: 36px;
    margin-top: -4px;
    float: right;
    margin-left: 12px;
  }
  .layout-wrapper .topbar-items > li.profile-item button .topbar-item-name {
    display: block;
    float: left;
    color: #ffffff;
  }
  .layout-wrapper .topbar-items > li.profile-item button .topbar-item-role {
    display: block;
    font-size: 12px;
    color: #ffffff;
  }
  .layout-wrapper .topbar-items > li > ul {
    position: absolute;
    top: 50px;
    right: 5px;
    width: 250px;
    background-color: #ffffff;
    border-top: 4px solid #6c757d;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    animation-duration: 0.5s;
  }
  .layout-wrapper .topbar-items > li > ul:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid #6c757d;
    content: " ";
    position: absolute;
    top: -15px;
    left: 232px;
  }
  .layout-wrapper.menu-layout-static .layout-menu-container {
    transition: transform 0.3s;
  }
  .layout-wrapper.menu-layout-static .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(-100%);
  }
  .layout-wrapper.menu-layout-static .layout-main,
.layout-wrapper.menu-layout-static .layout-footer {
    margin-left: 235px;
    transition: margin-left 0.3s;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-menu-container {
    transform: translateX(-100%);
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-main,
.layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-footer {
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .topbar .topbar-right #menu-button i {
    transform: rotate(180deg);
  }
  .layout-wrapper.menu-layout-static .layout-mask {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .topbar {
    box-shadow: none;
  }
  .layout-wrapper.menu-layout-horizontal .topbar .topbar-left {
    background-color: #343a40;
    background-image: -webkit-gradient(linear, left top, right top, from(#343a40), to(#424a51));
    background-image: -webkit-linear-gradient(to right, #343a40, #424a51);
    background-image: -moz-linear-gradient(to right, #343a40, #424a51);
    background-image: -ms-linear-gradient(to right, #343a40, #424a51);
    background-image: -o-linear-gradient(to right, #343a40, #424a51);
    background-image: linear-gradient(to right, #343a40, #424a51);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#343a40", endColorStr="#424a51", gradientType="1");
    box-shadow: none;
    margin: 12rem 0;
  }
  .layout-wrapper.menu-layout-horizontal .topbar .topbar-right {
    background-color: #343a40;
    background-image: -webkit-gradient(linear, left top, right top, from(#343a40), to(#424a51));
    background-image: -webkit-linear-gradient(to right, #343a40, #424a51);
    background-image: -moz-linear-gradient(to right, #343a40, #424a51);
    background-image: -ms-linear-gradient(to right, #343a40, #424a51);
    background-image: -o-linear-gradient(to right, #343a40, #424a51);
    background-image: linear-gradient(to right, #343a40, #424a51);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#343a40", endColorStr="#424a51", gradientType="1");
  }
  .layout-wrapper.menu-layout-horizontal .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container {
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 60px;
    height: auto;
    background-color: #424a51;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu {
    padding-bottom: 0;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu:last-child {
    margin-top: 0;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li {
    float: left;
    position: relative;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > .p-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > a:hover,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > .p-link:hover {
    background-color: #e8e8e8;
    color: #212529;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > a:hover i,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > .p-link:hover i {
    color: #212529;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > a .menuitem-toggle-icon,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > .p-link .menuitem-toggle-icon {
    margin-top: 4px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul {
    padding: 5px 0;
    z-index: 100;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 250px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top: 4px solid #6c757d;
    max-height: none;
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li .p-link {
    padding: 10px 16px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul {
    position: static;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul li a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul li .p-link {
    padding-left: 32px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul li a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul li .p-link {
    padding-left: 48px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul ul li a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul ul li .p-link {
    padding-left: 64px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul ul ul li a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul ul ul li .p-link {
    padding-left: 80px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul ul ul ul li a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li ul ul ul ul ul li .p-link {
    padding-left: 96px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li.active-menuitem > a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li > ul li.active-menuitem > .p-link {
    color: #6c757d;
    background-color: #e8e8e8;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li.active-menuitem > .arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid #6c757d;
    content: " ";
    position: absolute;
    top: 38px;
    left: 20px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li.active-menuitem > a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li.active-menuitem > .p-link {
    color: #6c757d;
    background-color: #e8e8e8;
    border-left-color: transparent;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li.active-menuitem > a i,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li.active-menuitem > .p-link i {
    color: #6c757d;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu > li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu li a .menuitem-badge,
.layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu li .p-link .menuitem-badge {
    margin-right: 0;
    margin-left: 4px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu li.layout-root-menuitem > button {
    display: block !important;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container ul.layout-menu li.layout-root-menuitem .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark {
    background-color: #363a41;
    background-image: -webkit-gradient(linear, left top, right top, from(#363a41), to(#363a41));
    background-image: -webkit-linear-gradient(to right, #363a41, #363a41);
    background-image: -moz-linear-gradient(to right, #363a41, #363a41);
    background-image: -ms-linear-gradient(to right, #363a41, #363a41);
    background-image: -o-linear-gradient(to right, #363a41, #363a41);
    background-image: linear-gradient(to right, #363a41, #363a41);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#363a41", endColorStr="#363a41", gradientType="1");
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu > li > ul {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu > li > a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu > li > .p-link {
    color: #ffffff;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu > li > a i,
.layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu > li > .p-link i {
    color: #ffffff;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li a:hover,
.layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li .p-link:hover {
    background-color: #4a4d54;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > a,
.layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > .p-link {
    color: #b5babe;
    background-color: #282b30;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > a i,
.layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li.active-menuitem > .p-link i {
    color: #b5babe;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container.layout-menu-dark ul.layout-menu li ul {
    background-color: #363a41;
    background-image: -webkit-gradient(linear, left top, right top, from(#363a41), to(#363a41));
    background-image: -webkit-linear-gradient(to right, #363a41, #363a41);
    background-image: -moz-linear-gradient(to right, #363a41, #363a41);
    background-image: -ms-linear-gradient(to right, #363a41, #363a41);
    background-image: -o-linear-gradient(to right, #363a41, #363a41);
    background-image: linear-gradient(to right, #363a41, #363a41);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#363a41", endColorStr="#363a41", gradientType="1");
  }
  .layout-wrapper.menu-layout-horizontal .layout-main {
    padding-top: 115px;
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-footer {
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-mask {
    display: none;
  }
  .layout-wrapper.menu-layout-slim .topbar {
    left: 75px;
    width: calc(100% - 75px);
  }
  .layout-wrapper.menu-layout-slim .topbar .topbar-left {
    background: transparent;
    box-shadow: none;
  }
  .layout-wrapper.menu-layout-slim .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container {
    width: 75px;
    overflow: visible;
    z-index: 100;
    top: 0;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .profile {
    height: 60px;
    padding-top: 7px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .profile > button .profile-name,
.layout-wrapper.menu-layout-slim .layout-menu-container .profile > button .profile-role,
.layout-wrapper.menu-layout-slim .layout-menu-container .profile > button i {
    display: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu {
    padding: 0;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li {
    position: relative;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li.layout-root-menuitem > button {
    display: block !important;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li.layout-root-menuitem .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > button {
    text-align: center;
    padding: 15px;
    padding-left: 20px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > a i:first-child,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > button i:first-child {
    font-size: 19px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > a span,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > a i.menuitem-toggle-icon,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > button span,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > button i.menuitem-toggle-icon {
    display: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > a:hover + .layout-menu-tooltip,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > button:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid #cccccc;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 73px;
    min-width: 200px;
    max-height: none;
    display: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li .p-link {
    padding-left: 16px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul li a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul li .p-link {
    padding-left: 32px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul li a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul li .p-link {
    padding-left: 48px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul ul li a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul ul li .p-link {
    padding-left: 64px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul ul ul li a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul ul ul li .p-link {
    padding: 80px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul ul ul ul li a,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li > ul li ul ul ul ul ul li .p-link {
    padding: 96px;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a:hover + .layout-menu-tooltip,
.layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li.active-menuitem > .p-link:hover + .layout-menu-tooltip {
    display: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container .layout-menu > li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container.layout-menu-dark {
    border: none;
  }
  .layout-wrapper.menu-layout-slim .layout-menu-container.layout-menu-dark .layout-menu > li > ul {
    border: none;
    background-color: #363a41;
    background-image: -webkit-gradient(linear, left top, right top, from(#363a41), to(#363a41));
    background-image: -webkit-linear-gradient(to right, #363a41, #363a41);
    background-image: -moz-linear-gradient(to right, #363a41, #363a41);
    background-image: -ms-linear-gradient(to right, #363a41, #363a41);
    background-image: -o-linear-gradient(to right, #363a41, #363a41);
    background-image: linear-gradient(to right, #363a41, #363a41);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#363a41", endColorStr="#363a41", gradientType="1");
  }
  .layout-wrapper.menu-layout-slim .layout-main {
    margin-left: 75px;
  }
  .layout-wrapper.menu-layout-slim .layout-footer {
    margin-left: 75px;
  }
}
@media (max-width: 1024px) {
  .layout-wrapper.menu-layout-static .topbar .topbar-right #menu-button i {
    transform: rotate(180deg);
  }
  .layout-wrapper.menu-layout-static .layout-menu-container {
    transform: translateX(-100%);
    transition: transform 0.3s;
  }
  .layout-wrapper.menu-layout-static .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(0) !important;
  }
  .layout-wrapper.menu-layout-static .layout-main,
.layout-wrapper.menu-layout-static .layout-footer {
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .layout-menu-container {
    z-index: 999999999;
    transform: translateX(0);
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .topbar {
    z-index: 999999998;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .topbar .topbar-right #menu-button i {
    transform: rotate(0deg);
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .layout-main,
.layout-wrapper.menu-layout-static.layout-menu-static-active .layout-footer {
    margin-left: 0;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .layout-mask {
    display: block;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .topbar .topbar-right #menu-button.menu-button-rotate i {
    transform: rotate(180deg);
  }
  .layout-wrapper .topbar .topbar-right #topbar-menu-button {
    display: block;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items {
    position: absolute;
    top: 75px;
    right: 15px;
    width: 275px;
    animation-duration: 0.5s;
    display: none;
    background-color: #ffffff;
    border-top: 4px solid #6c757d;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid #6c757d;
    content: " ";
    position: absolute;
    top: -15px;
    left: 242px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a,
.layout-wrapper .topbar .topbar-right .topbar-items > li > button {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 10px 15px;
    color: #212529;
    position: relative;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a i,
.layout-wrapper .topbar .topbar-right .topbar-items > li > button i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    font-size: 16px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a:hover,
.layout-wrapper .topbar .topbar-right .topbar-items > li > button:hover {
    background-color: #e8e8e8;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a .topbar-item-name,
.layout-wrapper .topbar .topbar-right .topbar-items > li > button .topbar-item-name {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a .topbar-badge,
.layout-wrapper .topbar .topbar-right .topbar-items > li > button .topbar-badge {
    position: absolute;
    left: 30px;
    top: 5px;
    background-color: #3eb839;
    color: #ffffff;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a span,
.layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a img,
.layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a i,
.layout-wrapper .topbar .topbar-right .topbar-items > li > ul li button span,
.layout-wrapper .topbar .topbar-right .topbar-items > li > ul li button img,
.layout-wrapper .topbar .topbar-right .topbar-items > li > ul li button i {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.active-top-menu > a,
.layout-wrapper .topbar .topbar-right .topbar-items > li.active-top-menu > button {
    color: #6c757d;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.active-top-menu > ul li a,
.layout-wrapper .topbar .topbar-right .topbar-items > li.active-top-menu > ul li button {
    padding-left: 32px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.search-item {
    width: 100%;
    display: block;
    font-size: 16px;
    position: relative;
    padding: 10px 15px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.search-item .topbar-search input {
    top: 0;
    width: 100%;
    padding-right: 28px;
    border-color: #dee2e6;
    color: #212529;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.search-item .topbar-search input:focus {
    border-color: #dee2e6;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.search-item .topbar-search label {
    top: 1px;
    color: #212529;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.search-item .topbar-search i {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item .profile-image {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    margin-right: 14px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item span {
    vertical-align: middle;
    display: inline-block;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items.topbar-items-visible {
    display: block;
  }
}
@media (max-width: 399px) {
  .layout-wrapper .topbar .topbar-right #topbar-menu-button {
    right: 10px;
  }
  .layout-wrapper.layout-rtl .topbar .topbar-right #topbar-menu-button {
    left: 10px;
  }
}

