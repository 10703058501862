@use "_common.scss" as v;

h2#contact-us-header {
  max-height: 2rem;
}

div#contact-us-container {
  font-size: 1.2rem;

  h2 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }


  ul#stores-list {
    text-align: left;

    > li {
      margin: 2em 0;
      list-style-type: none;

      > div.store-name {
        font-variant-caps: small-caps;
        margin: 0.5em 0;
        font-weight: 500;
        font-size: 1.5rem;
      }

      > div.store-address {
        a {
          @include v.hover-link(rgba(96, 68, 46, 0.76), white);
        }

        max-width: fit-content;

        i {
          margin: 0.25em 0.25em !important;
        }
      }

      > ul {
        li {
          margin: 0.25em 0;
          max-width: fit-content;
          list-style-type: none;

          a {
            @include v.hover-link(rgba(96, 68, 46, 0.76), white);

            i {
              margin: 0 0.25em;
            }
          }


          text-decoration: none;
        }
      }
    }
  }

  #contact-us-card {

    font-size: 1.25rem;

    small {
      font-size: .75em;
      color: rgba(131, 24, 24, 0.56);
    }

    .p-card-subtitle {
      font-size: 1rem;
    }

    button {
      font-size: 1.30rem;
      @include v.bordered-button(rgba(96, 68, 46, 0.76), whitesmoke);
      font-variant-caps: all-small-caps;
    }

    label {
      font-size: 1rem;
    }

    .p-inputtext {
      font-size: 1.2rem;
      padding: .5rem 0.25rem;
    }

    input {
      line-height: 1.4rem;
    }
  }
}
