@mixin hover-link($color, $bgColor) {
  color: $color;
  border-bottom: 1px dotted $color;
  padding: 0 .5em;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    color: $bgColor;
    background: $color;
  }
}

.small-caps {
  font-variant-caps: small-caps;
}

.all-small-caps {
  font-variant-caps: all-small-caps;
}

@mixin bordered-button($color, $bgColor) {
  color: $color;
  border: 1px solid $color;
  background-color: $bgColor;

  &:hover {
    color: $bgColor;
    background-color: $color;
    border: 1px solid $color;
  }
}

@mixin outlined-text($strokeWidth, $strokeColor, $fillColor) {
  // strokeWidth: 1px
  -webkit-text-stroke: $strokeWidth $strokeColor;
  -webkit-text-fill-color: $fillColor;
}
