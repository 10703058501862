
nav {
  padding: .5em 0;

  &#top-nav {
    background-color: rgba(150, 112, 65, .35);;
  }

  div#mobile-navbar {
    height: 100px;
    background: rgba(250, 250, 250, 0.34);
    display: flex;
    overflow-x: scroll;
    justify-content: space-between;
    max-width: 100%;

    .mobile-rounded {
      border: 1px transparent;
      min-width: 100px;
      text-align: center;
      vertical-align: middle;

      img {
        border-radius: 10%;
        max-height: 95%;
        box-shadow: 0 30px 50px rgb(0 0 0 / 0.2);
      }
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    padding: 0;
  }

  ul#desktop-navbar {
    display: none;
    align-items: center;

    > li.desktop-navbar-item {
      font-size: 1.25em;
      font-weight: 600;
      font-variant-caps: all-small-caps;
      text-align: center;
    }

    @media (min-width: 1024px) {
      display: flex;
      min-height: 2rem;
      width: 100%;
      justify-content: center;

      > li.desktop-navbar-item {
        list-style-type: none;
        margin: 0 0.15em;
        transition-duration: 0.75s;


        display: block;
        height: 100%;
        padding: .1em 5rem;
        cursor: pointer;
        color: #573a0f;
        background-image: linear-gradient(270deg, #b99f7f 0%, #dcc3a4 20%, #dcc3a4 80%, #b99f7f 100%);
        border-radius: 2px;
        border: 1px solid #866e51;

        ul.overlay-text-list {
          opacity: 0;
          background: whitesmoke;
          position: absolute;
          //margin-top: 1rem;
          align-self: center;
          left: 25rem;
          right: 25rem;
          text-align: left;
          transition: all 0.5s ease;
          visibility: hidden;
          cursor: auto;
          z-index: 9999;
          height: 0;

          li.overlay-text-li, a.overlay-text-link {
            height: 0;
          }

          ul.overlay-img-list, li.overlay-img-li, a.overlay-img-link {
            height: 0;
            > div {
              height: 0;
              > img {
                height: 0;
              }
            }
          }
        }

        &:hover {
          background-image: linear-gradient(to left, #573a0f, #5d4a2f);
          color: white;
          border: 1px solid #fabc95;
          height: auto;

          ul.overlay-text-list {
            padding: 1rem 3rem;
            opacity: 1;
            height: 200px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            border-radius: 1rem;
            visibility: visible;

            li.overlay-text-li, a.overlay-text-link {
              list-style-type: none;
              font-variant-caps: small-caps;
              width: 20rem;
              color: #424242;
              margin: .25em 0;
              padding: 0 .25em;
              align-items: center;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              height: 2rem;
              vertical-align: middle;

              > div {
                display: none;
              }

              &:hover, &:focus-within {
                background: #8a8484;
                color: white;
                border-radius: 4px;

                > div {
                  display: block;
                  float: right;
                  align-items: center;
                }

                ul.overlay-img-list {
                  display: flex;
                  height: 80%;
                  width: 50%;


                  justify-content: space-evenly;
                  flex-wrap: wrap;

                  margin: 1.5rem 0;
                  right: 25%;
                  position: absolute;

                  li.overlay-img-li, a.overlay-img-link {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 25%;

                    &:hover {
                      border: 1px solid #573a0f;
                      border-radius: 5px;
                    }

                    > div {
                      display: flex;
                      text-align: center;
                      width: 100%;
                      height: 100%;
                      justify-content: center;

                      > img {
                        display: block;
                        object-fit: contain;
                        height: 70%;
                        border-radius: 3px;
                        box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
                      }
                    }
                  }
                }
              }

              ul.overlay-img-list {
                display: none;
                top: 0;
                height: 0;
                width: 0;

                li.overlay-img-li, a.overlay-img-link {
                  text-align: center;
                  display: none;
                  flex-direction: column;
                  height: 0;

                  > div {
                    display: none;
                    height: 0;

                    > img {
                      display: none;
                      height: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}