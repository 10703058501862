$topbarLeftGradientStartBgColor: #343a40;
$topbarLeftGradientEndBgColor: #424a51;
$topbarRightGradientStartBgColor: #343a40;
$topbarRightGradientEndBgColor: #424a51;
$topbarTextColor: #ffffff;
$menuGradientStartBgColor: #ffffff;
$menuGradientEndBgColor: #ffffff;
$menuitemHoverBgColor: #e8e8e8;
$menuitemActiveColor: #6c757d;
$menuitemActiveBgColor: #e8e8e8;
$menuButtonBgColor: #ffffff;
$menuButtonColor: #343a40;
$badgeBgColor: #3eb839;
$badgeColor: #ffffff;
$darkMenuGradientStartBgColor: #363a41;
$darkMenuGradientEndBgColor: #363a41;
$darkMenuHoverBgColor: #4a4d54;
$darkMenuMenuitemColor: #ffffff;
$darkMenuMenuitemActiveColor: #b5babe;
$darkMenuMenuitemActiveBgColor: #282b30;

@import "./sass/_layout.scss";
