@use "_common.scss" as v;

.collection-page-container {
  > h1 {
    font-weight: 800;
    font-family: Algreya, serif;
    color: rgb(58, 32, 11);
    font-size: 2rem;
    text-align: center;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  > div.content {
    margin: 2rem 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;

    h1 {
      font-weight: 800;
      font-family: Algreya, serif;
      color: rgb(58, 32, 11);
      font-size: 2rem;
      text-align: center;
      display: none;

      @media screen and (min-width: 992px) {
        display: block;
      }
    }

    > div.description {
      padding: 3em 5em;
      @media screen and (min-width: 992px) {
        width: 50vw;
      }

      p {
        font-size: 1.25rem;
        @media screen and (min-width: 992px) {
          margin: 5rem;
        }
      }
    }

    #collection-open-instagram {
      @include v.bordered-button(rgb(222, 28, 98), whitesmoke);
      font-size: 1.1rem;
      padding: 1em 1rem;
      height: 3rem;
    }

    @media screen and (min-width: 992px) {
      flex-wrap: nowrap;
      flex-direction: row-reverse;
    }


    img {
      margin: 2em 0;
      max-height: 70vh;
      max-width: 90vw;
      box-shadow: 0 30px 50px rgb(0 0 0 / 0.2);
      border-radius: 5px;
      object-fit: contain;
    }

  }

  div.other-images {
    margin-top: 4rem;
    text-align: -webkit-center;
    text-align: -moz-center;

    h3 {
      text-align: center;
      width: 100%;
      padding: 1em 0;
      border-bottom: 1px solid dimgray;
      border-top: 1px solid dimgray;
    }

    img {
      max-height: 60vh;
      //max-width: 50vw;
    }

    #other-images-carousel {
      @media screen and (min-width: 992px) {
        max-width: 50vw;
      }
      max-width: 98vw;
      text-align: center;
    }

    @media screen and (min-width: 992px) {
      flex-wrap: nowrap;
    }
  }

}

.collections-container {
  justify-content: center;

  > a {
    display: block;
    text-align: center;

    img {
      max-width: 90%;
      max-height: 90vh;
      margin: 2em 0;
    }
  }
}

.collections-load-more {

}