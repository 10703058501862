$fontFamily: -apple-system, Algreya, Roboto, "Helvetica", Arial, sans-serif;
$fontSize: 14px;
$textColor: #212529;
$textSecondaryColor: #6c757d;
$borderRadius: 4px;
$dividerColor: #dee2e6;
$transitionDuration: 0.3s;
$disabledBgColor: #eeeeee;
$primeIconFontSize: 1.286em;

/* Predefined Colors */
$lightestGray: #f5f5f5;
$lightGray: #cccccc;
$gray: #999999;
$darkGray: #777777;
$white: #ffffff;

$blue: #007bff;
$purple: #9189fd;
$orange: #ffbf79;
$lightBlue: #8dc8ff;
$pink: #f790c8;
$indigo: #6610f2;
$green: #3e9018;
$red: #da2f31;
$orange: #ffb200;
$teal: #599597;
$black: #000000;
$yellow: #ffd644;

$inputInvalidBorderColor: #b94a48;
$inputInvalidBgColor: #ffffff;

$menuHoverBgColor: #f4f4f4;
$bodyBgColor: #eef2f6;
