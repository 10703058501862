@use "_common.scss" as v;

.product-card {
  margin: 1em 1em;
  min-width: 40vw;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgb(239, 236, 232) 5%, #ffffff 10%, #ffffff 90%, rgb(239, 236, 232) 100%);
  border-radius: 2px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.26);

  @media (min-width: 576px) {
    min-width: auto;
    width: 20%;
    margin: 1.5em 1.5em;
    min-height: 60px;
  }

  div.header, div.footer {
    padding: .5em 0;
    text-align: center;
    font-weight: 500;
    color: #3b2002;
  }

  div.img-div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;

    img {
      max-height: 500px;
      max-width: 100%;
      min-height: 100px;
      height: auto;
      color: dimgray;
    }
  }
}

h3#product-sku {
  font-variant-caps: small-caps;
  letter-spacing: .1em;
  margin: 0 0.1em;
}

#reach-us {
  font-size: 1.2em;
  color: dimgray;
  padding: 1em 0;
  border-top: 1px dotted dimgray;
  border-bottom: 1px dotted dimgray;

  #reach-us-text {
    font-size: .9em;
  }

  a#product-whatsapp-link {
    @include v.hover-link(#016751, white);
    margin: 0 2em;
  }

  a#product-call-link {
    @include v.hover-link(dimgray, white);
    margin: 0 2em;
  }
}

#product-desc {
  max-height: 100px;
}

#btn-product-inquiry {
  width: 70%;
  @media (min-width: 576px) {
    width: 30%;
  }
}

#btn-product-heart {
  background: whitesmoke;
  border-color: #bb5757;
  color: #bb5757;

  &:hover {
    color: white;
    background: #bb5757;
    border-color: #bb5757;
  }
}

#product-details-container {
  margin: 1em 0;
  padding: 2em;
  background: rgba(220, 220, 220, 0.8);
  border-radius: 5px;
  font-size: .85em;

  > h3 {
    text-align: center;
  }

  .component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #a19d9d;
    padding: 3em 0;
  }

  .line-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c0bbbb;
    padding: 0.7em;

    .label {
      color: dimgray;
    }

    .value {
      color: #262525;
    }
  }
}

#preview-img-container > img {
  width: 100%;
  height: auto;
}