
.product-cards-filter {
  width: 100%;
  padding: 1em 1em;
  font-variant-caps: all-petite-caps;

  @media (min-width: 576px) {
    width: 50%;
  }
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}