
body {
  min-height: 100vh;

  div#root {
    min-height: 100vh;
  }

  div#under-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    section#section {
      display: grid;
      justify-content: center;
      flex: 1 0 auto;

      > div {
        width: 100vw;
        @media (min-width: 1440px) {
          width: 50vw;
        }
        @media (min-width: 1366px) {
          width: 75vw;
        }
        @media (min-width: 1024px) {
          width: 80vw;
        }
      }
    }
  }
}

header#header {
  //background: #4f3110; /* old browsers */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
  //background: #a90329; /* Old browsers */
  background: -moz-linear-gradient(top, #502d0e 0%, #311c01 10%, #3b2002 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #502d0e 0%, #311c01 10%, #3b2002 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #502d0e 0%, #311c01 10%, #3b2002 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a90329', endColorstr='#6d0019', GradientType=0); /* IE6-9 */

  color: white;
  align-items: center;
  display: flex;
  justify-content: center;


  #top-bar {
    display: flex;
    text-shadow: rgb(131, 131, 131) 0 0 5px;
    padding: 0.5em 0.5em;

    align-items: center;
    //box-shadow: 0 2px 0px -1px rgba(107, 98, 98, 0.1);

    width: 100vw;
    height: 90px;


    @media (min-width: 1440px) {
      width: 50vw;
      height: auto;
    }
    @media (min-width: 1366px) {
      width: 75vw;
      height: auto;
    }
    @media (min-width: 1024px) {
      width: 80vw;
      height: auto;
    }

    .overlaid {
      display: none;
    }

    #logo-div {
      width: auto;
      -moz-transition: width 0s ease 0.5s;
      transition: width 0s ease 0.5s;

      img {
        opacity: 1;
        -moz-transition: opacity 0s ease 0.5s;
        transition: opacity 0s ease 0.5s;
        color: white;
        display: inline-block;
        -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
        mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
        background-repeat: no-repeat;
        animation: shimmer 3s infinite;
      }

      &.hidden {
        width: 0;
        -moz-transition: width 0s ease;
        transition: width 0s ease;

        img {
          opacity: 0;
          -moz-transition: opacity 0s ease 0s;
          transition: opacity 0s ease 0s;
        }
      }

      @media (min-width: 1024px) {
        width: 25%;
      }
    }

    #search {
      width: 0;
      visibility: hidden;
      overflow-x: hidden;

      transition: visibility 0s ease;
      -moz-transition: visibility 0s ease;
      -webkit-transition: visibility 0s ease;

      input {
        width: 0;
        opacity: 0;
        visibility: hidden;
        transition: width 0s;
        -moz-transition: width 0s;
        -webkit-transition: width 0s;
        margin-left: 2rem;
        left: 0;

        box-shadow: none;

        background-color: inherit;
        color: white;
        font-weight: 600;
        border: 0;
        //border-bottom: 1px dotted white;

        &:focus {
          outline: none;
        }
      }

      &.visible {
        visibility: visible;
        width: 100%;

        span {
          width: 80%;
          border-bottom: 1px dotted white;
          position: relative;
          display: inline-block;

          &:focus-within {
            border-bottom: 2px solid white;
            border-radius: 5px;
          }

          input {
            visibility: visible;
            opacity: 1;
            line-height: 2rem;
            left: auto;
            width: auto;
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
          }
          #hide-search-icon {
            visibility: visible;
          }
        }
      }

      #hide-search-icon {
        cursor: pointer;
      }

      @media (min-width: 768px) {
        display: inline-block;
        visibility: visible;
        width: 50%;

        span {
          min-width: 100%;
          border-bottom: 1px dotted white;
          position: relative;
          display: inline-block;

          &:focus-within {
            border-bottom: 2px solid white;
            border-radius: 5px;
          }

          input {
            width: 90%;
            line-height: 2.5em;
            visibility: visible;
            opacity: 1;
            //border-radius: 5px;
          }
        }

        #hide-search-icon {
          visibility: hidden;
        }
      }
    }

    #search-icon {
      display: block;
      padding: 1em 1em;
      font-size: 1.5em;
      visibility: visible;
      -moz-transition: visibility 0s ease 0.5s;
      transition: visibility 0s ease 0.5s;
      cursor: pointer;
      margin: auto;

      &.hidden {
        //display: none;
        visibility: hidden;
        -moz-transition: visibility 0s;
        transition: visibility 0s;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }

    div#menu-icon {
      right: 0;
      position: absolute;
      padding: 1em 1em;
      font-size: 1.5em;
      cursor: pointer;
    }

    @media (min-width: 768px) {
      div#menu-icon {
        display: none;
      }
    }
  }
}

#menu-overlay {
  visibility: hidden;
  height: 0;
  width: 0;

  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  border-radius: 20px;
  padding: 0 10px;

  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;

  #overlay-close-icon {
    display: none;
  }

  z-index: 9999;

  &.visible {
    background-color: rgba(34, 23, 14, 0.85);
    color: white;
    visibility: visible;
    height: 100vh;
    width: 100vw;
    border-radius: 0;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;

    #overlay-close-icon {
      display: block;
      right: 0;
      position: absolute;
      padding: 1em 1em;
      font-size: 1.5em;
      cursor: pointer;
    }
    ul {
      width: auto;
    }
  }
}

#menu {
  a {
    font-size: 1.25rem;
    color: black;
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
    mask-position: left;
  }
}
