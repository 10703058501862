@use "_common.scss" as v;


.banner-div {
  width: 100%;
  background: white;
  text-align: center;
  display: flex;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    object-fit: contain;
    width: auto;
    max-width: 95%;
  }

  a {
    display: block;
  }
}

div#banner-2 {
  img {
    max-height: 30vh;
    @media screen and (min-width: 992px) {
      max-height: inherit;
    }
  }
}

div#banner-1 {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  opacity: 0.8;
  height: 20vh;

  background-image: url("/public/legacy-images/temple-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;


  h4 {
    height: 2rem;
    padding: 0;
    margin: 0;
    //color: rgb(58, 32, 11);
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    background: -webkit-linear-gradient(top, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(to top, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to top, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
    //background: rgb(58, 32, 11);
    vertical-align: bottom;
    font-family: Algreya, serif;
    color: white;
  }

  @media screen and (min-width: 768px) {
    height: 20vh;
    flex-direction: row;
    justify-content: start;

    h4 {
      font-size: 3rem;
      flex-basis: 30%;
      height: 100%;
      align-self: center;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1rem 5rem;
      background: -webkit-linear-gradient(left, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
      background: -moz-linear-gradient(to right, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, rgb(134, 70, 38) 65%, rgba(255, 255, 255, 0) 100%);
      @include v.outlined-text(.4px, black, white)
    }
  }

  @media screen and (min-width: 992px) {
    height: 30vh;
  }
  @media screen and (min-width: 1200px) {
    height: 40vh;
  }
}

div#banner-2 {
  background: #c9cbd2;
  justify-content: space-evenly;
  border-radius: 10px;

  > div {
    border-radius: 5px;
    box-shadow: 0 -3em 3em rgba(241, 235, 235, 0.35), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(229, 228, 228, 0.55);
  }
}

div#top-narrow-banner {
  height: 0;
  display: flex;
  width: 100%;
  text-align: center;
  visibility: hidden;
  background: #235d2f;
  color: white;
  border-radius: 2px;

  &.visible {
    visibility: visible;
    justify-content: center;
    height: 2rem;
    align-items: center;

    span {
      width: 85%;
    }

    i {
      cursor: pointer;
      max-width: 2rem;
      margin: auto;
    }
  }

  -moz-transition: visibility, height ease-in-out .75s;
}