.login-body {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  height: 100vh;

  .login-image {
    height: calc(100% - 380px);
    //background: url("../../layout/images/login/bg-login.jpg") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .login-panel {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 0;
    border-radius: 0;
    bottom: 0;
    text-align: center;
    //background-color: #292b2c;

    .login-panel-content {
      //width: 25%;
      position: relative;
      margin: auto;
      top: 30px;
      color: #ffffff;

      .login-label {
        color: #cccccc;
        margin-bottom: 5px;
      }

      .login-input {
        margin-top: 5px;
      }

      .guest-sign-in {
        color: #999999;
        font-size: 12px;
      }

      .welcome-text {
        margin-bottom: 0;
      }

      .link-pane {
        padding-top: 24px;

        button {
          color: #cccccc;
        }
      }

      .button-pane {
        padding-top: 16px;
      }
    }
  }
}

@media (max-width: 640px) {
  .login-body {
    .login-panel {
      .login-panel-content {
        //padding: 0 50px;
        padding: 0 20px;
        width: 100%;
      }
    }
  }
}
