div#spinner {
  align-self: center;
  vertical-align: middle;

  circle.p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, spinner-color 6s ease-in-out infinite;
    stroke: dimgray;
  }
}

@keyframes spinner-color {
  100%,
  0% {
    stroke: #361e02;
  }
  40% {
    stroke: #5e3708;
  }
  66% {
    stroke: #a67336;
  }
  80%,
  90% {
    stroke: #daa96f;
  }
}
@-webkit-keyframes spinner-color {
  100%,
  0% {
    stroke: #361e02;
  }
  40% {
    stroke: #5e3708;
  }
  66% {
    stroke: #a67336;
  }
  80%,
  90% {
    stroke: #daa96f;
  }
}
@-moz-keyframes spinner-color {
  100%,
  0% {
    stroke: #361e02;
  }
  40% {
    stroke: #5e3708;
  }
  66% {
    stroke: #a67336;
  }
  80%,
  90% {
    stroke: #daa96f;
  }
}
