* {
  box-sizing: border-box;
}

.p-component {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-link {
  font-size: $fontSize;
  font-family: $fontFamily;
  border-radius: $borderRadius;

  &:focus {
    @include focused();
  }
}
