footer#footer {
  color: #3b2002;
  display: flex;
  justify-content: center;
  background: whitesmoke;
  flex-shrink: 0;
  margin-top: auto;
}

.footer-container {
  height: 200px;
  text-shadow: rgb(194, 194, 194) 0 0 15px;
  text-align: center;

  width: 100%;

  @media (min-width: 1440px) {
    width: 50%;
  }
  @media (min-width: 1366px) {
    width: 75%;
  }
  @media (min-width: 1024px) {
    width: 80%;
  }


  .footer-content {

    .sitemap {
      display: flex;
      justify-content: center;
      padding: 0.2em 0.2em;
      flex-wrap: wrap;
      background: #d2b79d;

      @media (min-width: 768px) {
        flex-wrap: wrap;
        padding: 1em 1em;
      }

      .sitemap-block {
        display: block;
        margin: 0 3em;
        width: 40%;

        @media (min-width: 768px) {
          width: 20%;
        }

        .block-header {
          font-variant-caps: small-caps;
        }

        ul.block-links {
          list-style-type: none;
          padding-left: 0;


          li {
            > a {
              color: #311c01;
              text-decoration: underline;
              text-underline-style: dotted;

              &:hover, &:active {
                background: #311c01;
                border-radius: 3px;
                color: white;
                text-decoration-style: dotted;
                text-decoration-skip-ink: auto;
              }
            }
          }
        }
      }
    }

    .brands {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.5rem;
      justify-content: center;
      color: #3d2808;
      margin: 1em 0;
      letter-spacing: 0.5em;
      padding: 0.5em 0.5em;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        padding: 1em 1em;
        flex-wrap: nowrap;
        letter-spacing: 1.5em;
      }
    }

  }

  .copyright {
    margin: 1em 0;
    font-size: 0.6rem;
    color: black;
    text-align: center;
    text-transform: uppercase;
    padding: 1em;
  }
}

